@use "../styles/theme";

:local(.label) {
  margin-bottom: 8px;
  color: theme.$text3-color;
  align-self: flex-start;
}

:local(.input-field) {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  white-space: break-spaces;
  line-height: 1.2rem;
}

:local(.fullWidth) {
  max-width: 100%;
}

:local(.error) {
  margin-top: 8px;
  color: theme.$error-color;
  align-self: flex-start;
  line-height: 1.2rem;
}

:local(.upload_error) {
  white-space: pre-line;
  width: 100%;
  text-align: center;
  font-weight: 700;
}

:local(.info) {
  margin-top: 8px;
  color: theme.$text2-color;
  align-self: flex-start;
}

:local(.info),
:local(.error) {
  font-size: 12px;
}
